import React, { ReactElement } from "react";

const IndexPage = (): ReactElement => {
  return (
    <>
      <h1>Hello World</h1>
    </>
  );
};

export default IndexPage;
